<template>
    <div id="app" style="overflow-y: hidden">
        <div class="content-wrapper" id="mainContent" ref="app" >
            <router-view/>
        </div>
    </div>
</template>
<script>
export default {
  name: 'App',
  methods: {
  }
}
</script>
