import Vue from 'vue'
import VueRouter from 'vue-router'
const Intro = () => import(/* webpackChunkName: "avatar" */ '../views/Intro')
const Quiz = () => import(/* webpackChunkName: "quiz" */ '../views/Quiz')
const Score = () => import(/* webpackChunkName: "info" */ '../views/Score')
const Actions = () => import(/* webpackChunkName: "info" */ '../views/Actions')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Intro',
    component: Intro
  },
  {
    path: '/actions',
    name: 'Actions',
    component: Actions
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: Quiz
  },
  {
    path: '/score',
    name: 'Score',
    component: Score
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
