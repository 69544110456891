const state = {
  answers: []
}

const getters = {
  answers (state) {
    return state.answers
  }
}

const actions = {
}

const mutations = {
  setAnswer (state, data) {
    state.answers.push(data)
  },
  clearAnswers (state) {
    state.answers = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
